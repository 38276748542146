* { margin: 0; padding: 0; }
  
html, body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  letter-spacing: -.3px;
  line-height: 1.17;
  color: #e7e7e7;
  background-color: black;
  background-image: url('/src/assets/img/background.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

#main-header {
  display: flex;
  flex-flow: wrap;
  flex-wrap: wrap;
  width: 100%;
  transition: all 1s;
  padding-right: 20px;
  z-index: 100;
  overflow: hidden;
}

#contatos-header {
  display: inline-flex;
  margin: 15px 15px 0 15px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

#contatos-header span {
  padding: 0 10px;
}

#contatos-header .fab {
  font-size: 2em;
  margin: 0 10px;
}

#contatos-header i {
  color: #2cd835;
  transition: all 0.4s;
}

#contatos-header i:hover {
  color: #adadad;
}

#header-menu {
  display: inline-flex;
  flex-flow: wrap;
  justify-content: center;
  width: 100%;
}

#header-menu div {
  height: 1%;
}

#logo {
  max-width: 100vw;
  width: 460px;
}

/*******MENU-BAR MOBILE*******/
#menu-bar-mobile {
  display: none;
  font-size: 3em;
  margin-top: 100px;
  height: 0;
  cursor: pointer;
}

#menu-bar-mobile i {
  color: #2cd835;
}

#menu-bar-mobile i:hover {
  color: #adadad;
}

#menu-bar-mobile span {
  padding-left: 10px;
}

#menu-mobile-modal {
  display: flex;
  flex-flow: column;
  font-size: 2em;
}

#menu-mobile-modal li {
 margin-top: 3vh;
 padding-bottom: 3vh;
 border-bottom: 1px solid #2cd835;
}

/*END******MENU-BAR MOBILE*******/

#menu {
  margin-top: 81px;
}

#menu a {
  padding-bottom: 10px;
}

#menu a:hover {
  border-bottom: 3px solid #2cd835;
}

#menu ul {
  display: inline-flex;
  flex-flow: wrap;
  flex-wrap: wrap;
  line-height: 3em;
  justify-content: center;
  list-style: none;
}

#menu li {
  font-size: 1.3em;
  margin: 0 25px;
}

a {
  color: white;
  text-decoration: none;
  font-style: normal;
  border-bottom: 2px solid transparent;
  transition: all 0.4s;
}

#estoque, #ofertas {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap; 
  margin: 0 20px;
  min-height: 100%;
  justify-content: center;
  align-content: center;
  overflow: hidden;  
}

#ofertas h1 {
  visibility: hidden;
}

.veiculos {
  display: flex;
  align-items: center;
  width: 231px;
  flex-flow: column;
  margin: 10px;
  cursor: pointer;
  transition: opacity 0.4s;
}

.veiculos:hover {
  opacity: 0.7;
}

.veiculos-ofertas {
  margin: 0 65px 40px 65px;
}

.veiculo-miniatura-promocao {
  width: 331px;
  height: 233px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.preco-tag-promocao {
  display: flex;
  width: 331px;
  color: #171f17;
  background-color: #2cd835;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.preco-tag-promocao-anterior-span {
  display: flex;
  font-size: .8em;
  text-decoration: line-through;
  padding: 0 10px;
  background-color: #1b1d1b;
  color: #a7a7a7;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 6px;
}

.preco-tag-promocao-span {
  display: flex;
  font-size: 1.1em;
  width: 100%;
  padding: 0 6px;
  justify-content: center;
  align-items: center;
}

.preco-tag-modo-promocao {
  display: flex;
  width: 105px;
  padding: 0 6px;
  background-color: #171f17;
  color: #2cd835;
  border-top-right-radius: 6px;
  justify-content: center;
  align-items: center;
}

.veiculo-miniatura {
  width: 231px;
  height: 166px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.preco-tag {
  display: inline-flex;
  font-size: 1em;
  width: 100%;
  justify-content: flex-end;
  justify-content: center;
  align-items: center;
  color: #242424;
  background-color: #e0e0e0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.preco-tag-span {
  width: 100%;
  text-align: center;
  padding-right: 6px;
}

.preco-tag-modo {
  padding: 3px;
  width: 105px;
  text-align: center;
  background-color: #1b1d1b;
  color: #a7a7a7;
  border-top-right-radius: 6px;
}

.veiculo-nome {
  text-align: center;
  line-height: 20px;
  margin-top: 10px;
}

.secao-titulo {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  font-size: 2em;
  width: 100%;
  color: #171f17;  
  background: #2cd835;
  padding: 10px 0;
  margin: 40px 0;
}

.secao-titulo-observacao {
  display: flex;
  font-size: .6em !important;
  align-self: center;
}

#maps {
  min-width: 100%;
  height: 400px;
}

#contato {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 30px;
  word-break: break-all;
}

#mensagem-enviada {
  visibility: hidden;
  top: 50%;
  padding: 5vw;
  font-size: 3em;
  text-align: center;
  word-break: normal;
  color: #2cd835;
  background-color: #2b2b2b;
  position: fixed;
  z-index: 999;
}

form {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  max-width: 99%;
}

form label {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

form span {
  font-size: 1.5em;
  margin: 10px 0;
}

input[type="text"], input[type="email"], textarea {
  font-size: 2em;
  width: 100%;
  margin: 10px 0;
  padding: 10px 20px;
  border: 2px solid #adadad;
  color: #2cd835;
  outline: none;
  background: transparent;
  border-radius: 6px;
  transition: border 0.4s;
}

input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
  border: 2px solid #2cd835;
}

form textarea {
  font-size: 1.5em;
  height: 150px;
  resize: none;
}

form button[type="submit"] {
  font-size: 1.5em;
  width: 100%;
  height: 60px;
  background-color: #2cd835;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
}

form button[type="submit"]:hover {
  background-color: #adadad;
}

#outros-contatos {
  display: flex;
  flex-flow: column;
  align-items: center;
}

#outros-contatos section {
  display: inline-flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

#outros-contatos i {
  font-size: 6em;
  color: #2cd835;
  margin: 30px;
}

#outros-contatos .fa-envelope {
  margin-right: 20px;
}

#outros-contatos span {
  font-size: 2em;
  text-align: center;
}

footer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
}

footer span {
  margin: 2px 0;
}

footer a {
  color: #2cd835;
  transition: all 0.4s;
}

footer a:hover {
  color: #adadad;
}

.close-section {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  background-color: black;
}

.close {
  margin: 10px 10px 10px 0;
}

.close  .fa {
  width: 50px;
  font-size: 3em !important;
  margin: 0;
  color: #2cd835;
}

.modal {
  visibility: hidden;
  text-align: center;
  top: 0;
  width: 100%;
  height: 100%;
  background: #191919f2;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  z-index: 100;
}

#veiculo-modal {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  margin: 45px 10px 35px 10px;  
}

#veiculo-modal h1 {
  font-size: 1.6em;
}

#veiculo-modal img {
  border-radius: 6px;
}

#veiculo-modal #wrapper-galeria {
  display: flex;
  flex-wrap: wrap;
  width: 330px
}

#veiculo-modal #galeria-fotos a {
  width: 78px;
  height: 57px; 
  margin: 2px;
  border-radius: 8px;
}

#veiculo-modal #galeria-fotos a:hover {
  opacity: 0.8;
}

#veiculo-modal ul {
  display: flex;
  flex-flow: column;
}

#veiculo-modal li {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding-bottom: 20px;
  border-bottom: 1px solid #2cd835;
}

#veiculo-modal li:last-child {
  border: none;
}

#veiculo-modal #veiculo {
  display: flex;
  flex-flow: wrap;
  margin-top: 50px;
  justify-content: center;
}

#veiculo-modal #dados-veiculo {
  width: 60%;
  margin: 0 15px;
}

#veiculo-modal .dados-veiculo-propriedade {
  font-size: 0.7em;
  padding: 10px 0;
  color: #8b8b8b;
}

#veiculo-modal .dados-veiculo-label {
  font-size: 0.9em;
}

#veiculo-modal .dados-veiculo-label::first-letter {
  text-transform: capitalize;
}

@keyframes fade-in-transition {
  from { opacity: 0 }
   to { opacity: 1 }
}

@keyframes fade-out-transition {
  from { opacity: 0 }
  to { opacity: 1 }
}